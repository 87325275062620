<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Upload Data Syarat & Ketentuan">
        <!-- search input -->
        <b-row class="mt-2 mb-2" align="end">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <span class="align-middle">Upload File</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'name'">
              <feather-icon icon="FolderIcon" size="17" /> -
              <!-- {{ props.row.name }} -->
              Syarat Abc
            </span>

            <span v-else-if="props.column.field === 'role'">
              <b-badge variant="warning"> Belum Upload </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="showModalEdit(props)"
                  variant="none"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                </b-button>
              </span>

              <span @click="deleteInfo(props)">
                <feather-icon icon="Trash2Icon" class="mr-50 cursor-pointer" />
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal detail Form Upload Syarat & Ketentuan-->
        <b-modal
          id="modal-detail-membership-seeker"
          ref="ref-payment-member-seeker"
          title="Form Upload Syarat & Ketentuan"
          :hide-footer="true"
          no-close-on-backdrop
        >
          <b-form>
            <b-form-group
              label="Tanggal"
              label-for="name"
              v-if="this.aksi === 'edit'"
            >
              <b-form-input
                id="name"
                placeholder="Tanggal"
                v-model="create_time"
                disabled
              />
            </b-form-group>

            <b-form-group label="Nama Membership" label-for="name">
              <b-form-select
                v-model="form.name"
                placeholder="Membership"
                :options="combo_membership"
                size="lg"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Harga (Rp.)" label-for="name">
              <b-form-input
                id="name"
                placeholder="Harga"
                v-model="form.price"
              />
            </b-form-group>

            <b-form-group label="Diskon (%)" label-for="name">
              <b-form-input
                id="name"
                placeholder="Diskon"
                v-model="form.discount"
              />
            </b-form-group>

            <b-form-group label="Deskripsi" label-for="name">
              <b-form-textarea
                id="textarea-default"
                placeholder="Deskripsi"
                v-model="form.description"
                rows="3"
              />
            </b-form-group>
          </b-form>

          <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="submit"
              >
                Simpan
              </b-button>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-modal>
        <!-- end modal detail perusahaan -->
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      combo_membership: ["FREE", "GOLD", "PLATINUM", "SILVER"],

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "Nama file",
          field: "name",
        },
        {
          label: "Terakhir diubah",
          field: "create_time",
        },
        {
          label: "Status",
          field: "role",
        },
        {
          label: "Aksi",
          field: "action",
          tdClass: "text-center",
        },
      ],
      rows: [],
      searchTerm: "",

      name: "",
      sort: "",

      form: {
        name: "FREE",
        price: 0,
        discount: 0,
        description: "",
        role: "JOB_SEEKER",
      },
      create_time: "",

      show: false,
      aksi: "",
      _id: "",
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search = "";
      if (this.name) {
        search = "&name=" + this.name;
      } else {
        search;
      }
      let sort = "";
      if (this.sort) {
        sort = "&sort=" + this.sort;
      } else {
        sort = "&sort=createTime";
      }

      return new Promise(() => {
        axios
          .get(
            "/v1/payment/admin/membership/all" +
              "?page" +
              page +
              "&size=" +
              size +
              sort +
              "&dir=-1&role=JOB_SEEKER" +
              search
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalTambah() {
      this.$refs["ref-payment-member-seeker"].show();
      this.aksi = "tambah";
    },

    showModalEdit(props) {
      this.form.name = props.row.name;
      this.form.price = props.row.price;
      this.form.discount = props.row.discount;
      this.form.description = props.row.description;
      this.create_time = props.row.create_time.substring(0, 10);
      this._id = props.row._id;

      this.$refs["ref-payment-member-seeker"].show();
      this.aksi = "edit";
    },

    submit() {
      if (this.aksi === "tambah") {
        return new Promise(() => {
          axios
            .post("v1/payment/admin/membership/", this.form)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Tambah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Tambah Data Membership Job Seeker Berhasil`,
                },
              });
              this.getData(this.paging.size, this.paging.page);
              this.$refs["ref-payment-member-seeker"].hide();
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      } else {
        return new Promise(() => {
          axios
            .put("v1/payment/admin/membership/" + "/" + this._id, this.form)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ubah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Ubah Data Membership Job Seeker Berhasil`,
                },
              });
              this.getData(this.paging.size, this.paging.page);
              this.$refs["ref-payment-member-seeker"].hide();
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      }
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("/v1/payment/admin/membership/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Membership Job Seeker Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    handleSearch(searching) {
      this.getData(this.paging.size, this.paging.page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
